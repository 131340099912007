<template>
    <div>
        <div class="nav-menu" v-if="showMenu">
            <div class="menu-item" v-for="item in navList" :key="item.name" @click="clickMenu(item)">
                <img :src="selectMenu==item.name?item.url:item.wurl" alt="">
                <div>{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import navList from '@/utils/navMenu'
import { getClassList } from "@/api/games";
import eventBus from '@/utils/eventBus'
    export default {
        data() {
            return {
                navList:[],
                selectMenu:'Hot',
                showMenu:false
            }
        },
        mounted() {
            this.getclasslist();
            eventBus.$on('showmenu',i=>{                
                this.showMenu = i;
            })
        },
        methods: {
            async getclasslist(){
                let navlist = await getClassList();
                navlist.forEach(item => {
                    navList.forEach(e=>{
                        if(item.key_name==e.name){
                            this.navList.push(e)
                        }
                    })
                });
            },
            clickMenu(item){
                this.selectMenu = item.name;
                if(this.$route.path=='/'){
                    eventBus.$emit('selectMenu',item.name);
                }else{
                    this.$router.push({
                        name: 'index',
                        params:{
                            menuKey:item.name
                        }
                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
    .nav-menu{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        height:100vh;
    }
}
@media (max-width: 768px) {
    .nav-menu{
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 999;
        height: calc(100vh - 50px);
    }
}
.nav-menu{
    &::-webkit-scrollbar {
        width: 0;
    }
    width: 168px;
    overflow: auto;
    background: #504BA9;
    .menu-item{
        width: 116px;
        margin: 27px auto;
        display: flex;
        align-items: center;
        img{
            width: 27px;
            height: 27px;
        }
        div{
            margin-left: 11px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
}
</style>